define("ui/authenticated/cluster/edit/route", ["exports", "shared/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterTemplateService: Ember.inject.service('clusterTemplates'),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    model: function model() {
      var globalStore = this.get('globalStore');
      var cluster = this.modelFor('authenticated.cluster');
      var modelOut = {
        originalCluster: cluster,
        cluster: cluster.clone(),
        kontainerDrivers: globalStore.findAll('kontainerDriver'),
        nodeTemplates: globalStore.findAll('nodeTemplate'),
        nodeDrivers: globalStore.findAll('nodeDriver'),
        psps: globalStore.findAll('podSecurityPolicyTemplate'),
        roleTemplates: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: globalStore.findAll('user'),
        clusterRoleTemplateBinding: globalStore.findAll('clusterRoleTemplateBinding'),
        me: Ember.get(this, 'access.principal')
      };

      if (!Ember.isEmpty(cluster.clusterTemplateRevisionId)) {
        Ember.setProperties(modelOut, {
          clusterTemplateRevisions: globalStore.findAll('clustertemplaterevision'),
          clusterTemplates: globalStore.findAll('clustertemplate')
        });
      }

      return Ember.RSVP.hash(modelOut);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var _model$clusterTemplat = model.clusterTemplateRevisions,
          clusterTemplateRevisions = _model$clusterTemplat === void 0 ? null : _model$clusterTemplat,
          cluster = model.cluster;
      var clusterTemplateRevisionId = cluster.clusterTemplateRevisionId;

      if (clusterTemplateRevisionId) {
        if (clusterTemplateRevisions) {
          var ctr = null;

          if (transition.queryParams && transition.queryParams.clusterTemplateRevision) {
            clusterTemplateRevisionId = transition.queryParams.clusterTemplateRevision;
          }

          ctr = clusterTemplateRevisions.findBy('id', clusterTemplateRevisionId);

          if (ctr) {
            Ember.set(model, 'clusterTemplateRevision', ctr); // This is breaking fields that already have values that don't match the template, like kubernetesVersion with 1.14.x
            // this.clusterTemplateService.cloneAndPopulateClusterConfig(cluster, ctr);
          } else {
            // user does not have access to the template that was used to launch a cluster
            // create a fake cluster that we'll use to turn into a "temaplate Revision" to be passed down to components
            // I am using JSON flow here to drop any of the dynamic UI only fields so we dont risk cross contamination with observables and the like.
            var tempCluster = JSON.parse(JSON.stringify(cluster.cloneForNew()));
            Ember.set(tempCluster, 'type', 'clusterSpec');
            var tempSpec = this.globalStore.createRecord(tempCluster); // a template revision has a "cluster config" that should be set which we'll fake with a parsed spec

            Ember.setProperties(tempCluster, {
              type: 'clusterTemplateRevision',
              clusterConfig: JSON.parse(JSON.stringify(tempSpec))
            });
            var tempRevision = this.globalStore.createRecord(tempCluster);
            Ember.set(model, 'clusterTemplateRevision', tempRevision);
          }
        }
      } // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"


      var kontainerDrivers = model.kontainerDrivers;
      var externalDrivers = kontainerDrivers.filter(function (d) {
        return d.uiUrl !== '' && d.state === 'active' && d.name.includes(model.cluster.provider);
      });
      var promises = {};
      externalDrivers.forEach(function (d) {
        if (Ember.get(d, 'hasUi')) {
          var jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, _this.get('app.proxyEndpoint'));
          var cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(_this, 'app.proxyEndpoint')); // skip setProperties cause of weird names

          Ember.set(promises, "".concat(d.name, "Js"), (0, _loadScript.loadScript)(jsUrl, "driver-ui-js-".concat(d.name)));
          Ember.set(promises, "".concat(d.name, "Css"), (0, _loadScript.loadStylesheet)(cssUrl, "driver-ui-css-".concat(d.name)));
        }
      });

      if (Ember.isEmpty(promises)) {
        return model;
      } else {
        return Ember.RSVP.hashSettled(promises).then(function (settled) {
          var allkeys = Object.keys(settled);
          allkeys.forEach(function (key) {
            if (Ember.get(settled, "".concat(key, ".state")) === 'rejected') {
              var tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
              var match = kontainerDrivers.findBy('id', tmp);
              console.log('Error Loading External Component for: ', match);

              if (match && Ember.get(match, 'scriptError') !== true) {
                Ember.set(match, 'scriptError', Ember.get(_this, 'intl').t('clusterNew.externalError'));
              }
            }
          });
        }).finally(function () {
          return model;
        });
      }
    },
    setupController: function setupController(controller
    /* , model*/
    ) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'step', 1);
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('errors', null);
        controller.set('provider', null);
      }
    },
    queryParams: {
      provider: {
        refreshModel: true
      },
      clusterTemplateRevision: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});